window.addEventListener('DOMContentLoaded', event => {
    let selects = document.querySelectorAll('#status-filter')
    let selectInstances = M.FormSelect.init(selects, {
        dropdownOptions: {onCloseStart: handleStatusFilterChange},
        classes: 'select-selected-multiple'
    })
    const parsedUrl = new URL(window.location)
    if (parsedUrl.searchParams.get('page')) {
        if (paginate) {
            let active = paginate.getElementsByClassName('active')
            active[0].classList.remove('active')
            let newActive = Array.prototype.slice.call(paginate.querySelectorAll('li')).filter(item => item.innerText === parsedUrl.searchParams.get('page'))[0]
            if (newActive) newActive.classList.add('active')
        }
    }
    if (paginate) {
        const paginateArray = Array.prototype.slice.call(paginate.querySelectorAll('li'))
        if (paginate.getElementsByClassName('active')[0].innerText === '1') {
            let paginateDisableArray = paginateArray.filter((item, index) => ![1, 2, 3, paginateArray.length - 2].includes(index) && !['chevron_right', 'chevron_left'].includes(item.innerText))
            paginateDisableArray.forEach((item, i) => {
                item.classList.add('hidden-pagination')
            });
            let finalArray = Array.prototype.slice.call(paginate.querySelectorAll('li')).filter(item => !item.classList.contains('hidden-pagination') && !['chevron_right', 'chevron_left'].includes(item.innerText))
            finalArray.forEach((item, i) => {
                if (i === 0) {
                    return
                }
                if (Number(item.innerText) - 1 !== Number(finalArray[i - 1].innerText)) {
                    const element = document.createElement('li')
                    element.innerText = '..'
                    paginate.insertBefore(element, paginate.children[Array.prototype.slice.call(paginate.querySelectorAll('li')).indexOf(item)])

                }
            });
            return
        }
        if (paginate.getElementsByClassName('active')[0].innerText === String(paginateArray.length - 2)) {
            const lastIndex = paginateArray.length - 2
            let paginateDisableArray = paginateArray.filter((item, index) => ![1, lastIndex - 2, lastIndex - 1, lastIndex].includes(index) && !['chevron_right', 'chevron_left'].includes(item.innerText))
            paginateDisableArray.forEach((item, i) => {
                item.classList.add('hidden-pagination')
            });
            let finalArray = Array.prototype.slice.call(paginate.querySelectorAll('li')).filter(item => !item.classList.contains('hidden-pagination') && !['chevron_right', 'chevron_left'].includes(item.innerText))
            finalArray.forEach((item, i) => {
                if (i === 0) {
                    return
                }
                if (Number(item.innerText) - 1 !== Number(finalArray[i - 1].innerText)) {
                    const element = document.createElement('li')
                    element.innerText = '..'
                    paginate.insertBefore(element, paginate.children[Array.prototype.slice.call(paginate.querySelectorAll('li')).indexOf(item)])

                }
            });
            return
        }
        const paginationNumber = Number(paginate.getElementsByClassName('active')[0].innerText)
        const disableArray = [1, paginationNumber, paginationNumber - 1, paginationNumber + 1, Number(paginate.getAttribute('data'))]
        let paginateDisableArray = paginateArray.filter((item, index) => !disableArray.includes(index) && !['chevron_right', 'chevron_left'].includes(item.innerText))
        paginateDisableArray.forEach((item, i) => {
            item.classList.add('hidden-pagination')
        });
        let finalArray = Array.prototype.slice.call(paginate.querySelectorAll('li')).filter(item => !item.classList.contains('hidden-pagination') && !['chevron_right', 'chevron_left'].includes(item.innerText))
        finalArray.forEach((item, i) => {
            if (i === 0) {
                return
            }
            if (Number(item.innerText) - 1 !== Number(finalArray[i - 1].innerText)) {
                const element = document.createElement('li')
                element.innerText = '..'
                paginate.insertBefore(element, paginate.children[Array.prototype.slice.call(paginate.querySelectorAll('li')).indexOf(item)])

            }
        });
    }

    if (parsedUrl.searchParams.get('date_range')) {
        const dateRangeSelect = document.getElementById('date-range-filter')
        for (let i = 0; i < dateRangeSelect.options.length; i++) {
            if (dateRangeSelect.options[i].value === parsedUrl.searchParams.get('date_range')) {
                dateRangeSelect.selectedIndex = i
                const dateRangeInputsWrapper = document.getElementById('date-range-input-wrapper')
                if (dateRangeSelect.options[i].value === '1' && dateRangeInputsWrapper.classList.contains('hidden')) {
                    dateRangeInputsWrapper.classList.remove('hidden')
                    const startDateInput = document.getElementById('start-date')
                    const endDateInput = document.getElementById('end-date')
                    startDateInput.value = parsedUrl.searchParams.get('start_date')
                    endDateInput.value = parsedUrl.searchParams.get('end_date')
                }
                document.getElementsByClassName('select-selected')[0].innerHTML = dateRangeSelect.options[i].innerHTML
                document.getElementsByClassName('select-selected')[0].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[0].children[0].innerHTML = `- ${dateRangeSelect.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[0].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[0].children[0].addEventListener('click', e => {
                    let url = removeExistingUrlParameters(['date_range'])
                    url.includes('?') ? window.location.replace(`${url}`) : window.location.replace(`${url}?page=1`)
                })
            }
        }
    }
    if (parsedUrl.searchParams.get('campaign_id')) {
        const campaignSelect = document.getElementById('campaign-filter')
        for (let i = 0; i < campaignSelect.options.length; i++) {
            if (campaignSelect.options[i].value === parsedUrl.searchParams.get('campaign_id')) {
                campaignSelect.selectedIndex = i
                document.getElementsByClassName('select-selected')[1].innerHTML = campaignSelect.options[i].innerHTML
                document.getElementsByClassName('select-selected')[1].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[1].children[0].innerHTML = `- ${campaignSelect.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[1].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[1].children[0].addEventListener('click', e => clearFilter('campaign_id'))
            }
        }
    }
    if (parsedUrl.searchParams.get('sending_address')) {
        const queryFilter = document.getElementById('email-filter')
        for (let i = 0; i < queryFilter.options.length; i++) {
            if (queryFilter.options[i].value === parsedUrl.searchParams.get('sending_address')) {
                queryFilter.selectedIndex = i
                document.getElementsByClassName('select-selected')[2].innerHTML = queryFilter.options[i].innerHTML
                document.getElementsByClassName('select-selected')[2].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[2].children[0].innerHTML = `- ${queryFilter.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[2].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[2].children[0].addEventListener('click', e => clearFilter('sending_address'))
            }
        }
    }
    if (parsedUrl.searchParams.get('status[]')) {
        const statusFilter = document.getElementById('status-filter')
        statusFilter.options[0].selected = false
        for (let i = 0; i < statusFilter.options.length; i++) {
            if (parsedUrl.searchParams.getAll('status[]').includes(statusFilter.options[i].value)) {
                statusFilter.options[i].selected = true
                const event = new Event('change')
                statusFilter.dispatchEvent(event)
            }
        }
    }

    const selectedSelects = document.getElementsByClassName('select-selected')

    if (selectedSelects) {
        selectedSelects.forEach((item, i) => {
            item.innerHTML = item.innerHTML.length >= 14 ? item.innerHTML.split('').slice(0, 14).join('') + '..' : item.innerHTML
        });
    }
})

// Pagination Component //
const paginate = document.getElementById('paginate')

if (paginate) {
    paginate.children.forEach((item, i) => {
        item.addEventListener('click', e => {
            let activeClass = paginate.getElementsByClassName('active')
            if (e.target.innerHTML === 'chevron_left') {
                Number(activeClass[0].innerText) === 1 ? {} : previousSibling(activeClass[0])
                handlePagination(paginate.getElementsByClassName('active')[0].innerText)
                return
            }
            if (e.target.innerHTML === 'chevron_right') {
                Number(activeClass[0].innerText) === Number(paginate.getAttribute('data')) ? {} : nextSibling(activeClass[0])
                handlePagination(paginate.getElementsByClassName('active')[0].innerText)
                return
            }
            if (e.target.innerHTML === '..') {
                return
            }
            activeClass[0].classList.remove('active')
            e.target.classList.add('active')
            handlePagination(paginate.getElementsByClassName('active')[0].innerText)
        })
    });
}

const previousSibling = element => {
    element.classList.remove('active')
    element.previousElementSibling.classList.add('active')
}

const nextSibling = element => {
    element.classList.remove('active')
    element.nextElementSibling.classList.add('active')
}

const handlePagination = number => {
    let url = new URL(window.location.href)
    if (url.searchParams.get('page') === number)
        return
    url = removeExistingUrlParameters(['page'])
    if (url.includes('?')) {
        window.location.replace(`${url}&page=${number}`)
        return
    }
    window.location.replace(`${url}?page=${number}`)
}
// Pagination Component End //

const removeExistingUrlParameters = names => {
    const url = new URL(window.location.href)
    names.forEach(name => url.searchParams.delete(name))
    return url.href
}

// Clear all parameter //
const clearAll = document.getElementById('clear-all')

if (clearAll) {
    clearAll.addEventListener('click', e => {
        const url = removeExistingUrlParameters(['date_range', 'start_date', 'end_date', 'sending_address', 'campaign_id', 'list_filter', 'page', 'status[]'])
        window.location.replace(`${url}?page=1`)
    })
}
// Clear all parameters end //

// Date Range Filter //

const dateRangeSelect = document.getElementById('date-range-filter')

if (dateRangeSelect)
    dateRangeSelect.addEventListener('change', e => {
        const dateRangeInputsWrapper = document.getElementById('date-range-input-wrapper')
        if (e.target.value && e.target.value === 'clear') {
            return
        }
        if (e.target.value && e.target.value === '1' && dateRangeInputsWrapper.classList.contains('hidden')) {
            dateRangeInputsWrapper.classList.remove('hidden')
            return
        } else dateRangeInputsWrapper.classList.add('hidden')
        const url = removeExistingUrlParameters(['date_range', 'start_date', 'end_date'])
        if (url.includes('?')) {
            window.location.replace(`${url}&date_range=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?date_range=${e.target.value}`)
    })

// Date range filter end //

// Email select //
const emailSelect = document.getElementById('email-filter')

if (emailSelect)
    emailSelect.addEventListener('change', e => {
        if (e.target.value && e.target.value === 'clear')
            return

        const url = removeExistingUrlParameters(['sending_address'])
        if (url.includes('?')) {
            window.location.replace(`${url}&sending_address=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?sending_address=${e.target.value}`)
    })
//Email select//

//campaign select//
const campaignSelect = document.getElementById('campaign-filter')

if (campaignSelect)
    campaignSelect.addEventListener('change', e => {
        if (e.target.value && e.target.value === 'clear') {
            return
        }

        const url = removeExistingUrlParameters(['campaign_id'])
        if (url.includes('?')) {
            window.location.replace(`${url}&campaign_id=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?campaign_id=${e.target.value}`)
    })
//campaign select//

// status select //
function handleStatusFilterChange() {
    const statusSelect = document.getElementById('status-filter')
    if (statusSelect) {
        const multiSelectInstance = M.FormSelect.getInstance(statusSelect)
        const selectedValues = multiSelectInstance.getSelectedValues().filter(value => value)
        const urlStatusParams = new URL(window.location.href).searchParams.getAll('status[]')
        if (
            (selectedValues.filter(value => !urlStatusParams.includes(value)).length === 0)
            && (urlStatusParams.filter(value => !selectedValues.includes(value)).length === 0)
        ) return
        const url = removeExistingUrlParameters(['status[]'])
        const valuesAsUrlParams = selectedValues.map(value => `status[]=${value}`).join('&')
        if (url.includes('?')) {
            window.location.replace(`${url}&${valuesAsUrlParams}`)
            return
        }
        window.location.replace(`${url}?${valuesAsUrlParams}`)
    }
}

// status select //

// Start/End date filter //
const startDateInput = document.getElementById('start-date')

if (startDateInput) {
    startDateInput.addEventListener('change', e => {
        if (!endDateInput.value || !e.target.value) return

        const url = removeExistingUrlParameters(['start_date', 'end_date', 'date_range'])
        const startDate = new Date(e.target.value).toDateString()
        const endDate = new Date(endDateInput.value).toDateString()
        if (url.includes('?')) {
            window.location.replace(`${url}&start_date=${startDate}&end_date=${endDate}&date_range=1`)
            return
        }
        window.location.replace(`${url}?start_date=${startDate}&end_date=${endDate}&date_range=1`)
    })
}

const endDateInput = document.getElementById('end-date')

if (endDateInput) {
    endDateInput.addEventListener('change', e => {
        if (!startDateInput.value || !e.target.value) return

        const url = removeExistingUrlParameters(['start_date', 'end_date', 'date_range'])
        const startDate = new Date(startDateInput.value).toDateString()
        const endDate = new Date(e.target.value).toDateString()
        if (url.includes('?')) {
            window.location.replace(`${url}&start_date=${startDate}&end_date=${endDate}&date_range=1`)
            return
        }
        window.location.replace(`${url}?start_date=${startDate}&end_date=${endDate}&date_range=1`)
    })
}
// Start/End date filter end //

const clearFilter = (filterName) => {
    const newURL = new URL(window.location)
    let urlGenerator = Array.from(newURL.searchParams.keys()).filter(e => e !== filterName)
    let toGoUrl = window.location.pathname
    urlGenerator.forEach((item, index) => {
        if (index === 0) {
            toGoUrl = toGoUrl + `?${item}=${newURL.searchParams.get(item)}`
            return
        }
        toGoUrl = toGoUrl + `&${item}=${newURL.searchParams.get(item)}`
    })
    window.location.replace(toGoUrl)
}
