handleClipboardClick = (event) => {
    const text = event.target.previousElementSibling ? event.target.previousElementSibling.innerText : event.target.parentElement.previousElementSibling.innerText;
    let elem = document.createElement("textarea");
    elem.textContent = text
    document.body.appendChild(elem);

    let selection = document.getSelection();
    let range = document.createRange();
    range.selectNode(elem);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");

    document.body.removeChild(elem);

    let x = document.getElementById("snackbar");
    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
        x.className = x.className.replace("show", "");
    }, 3000);
}

reloadWindow = (event) => {
    location.reload();
}