$(document).ready(function () {
    $(".verify-now-link").click(function () {
        email_client_id = $(this)[0].attributes["data-value"].value 
        M.Modal.init($(`#verify-modal-${email_client_id}`)[0]).open();
    });

    $(".email-alias-link").click(function () {
        M.Modal.init($("#email-alias-modal")[0]).open();
    });

    $(".usage_limit-link").click(function () {
        M.Modal.init($("#email-usage-modal")[0]).open();
    });

    $(".webhook-link").click(function () {
        M.Modal.init($("#webhook-modal")[0]).open();
    });

    $(".choose-email-client-link").click(function () {
        let url = window.location.href.split('/')
        url.pop()
        url = `${url.join('/')}/email_clients/new`
        window.location.href = url
    });

    $("#email-alias-form").validate({
        onkeyup: false,
        rules: {
            email: {
                required: true,
                validEmail: true,
            },
        },
        messages: {
            email: {
                required: "Please enter email",
                validEmail: "Please enter a valid email",
            },
        },
    });

    $("#usage-limit-form").validate({
        onkeyup: false,
        errorPlacement: function (error, element) {
            if (element[0].name === "frequency")
                error.insertAfter(element.closest("div")[0]);
            else error.insertAfter(element);
        },
        rules: {
            frequency: {
                required: true,
            },
            quantity: {
                required: true,
            },
        },
        messages: {
            frequency: {
                required: "Please Select the Frequency",
            },
            quantity: {
                required: "Please enter the quantity",
            },
        },
    });

    $("#webhook-form").validate({
        onkeyup: false,
        rules: {
            endpoint: {
                required: true,
            },
        },
        messages: {
            endpoint: {
                required: "Please enter the endpoint",
            },
        },
    });

    
});
