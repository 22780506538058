// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable";
import "regenerator-runtime/runtime";
//for using jquery in js.erb

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("jquery-datetimepicker");
require("jquery-validation");
require("packs/script.js");
require("packs/common.js");
require("packs/signup.js");
require("packs/signin.js");
require("packs/forgot-password.js");
require("packs/change-password.js");
require("packs/email-clients.js");
require("packs/contacts.js");
require("packs/lists.js");
require("packs/campaign.js");
require("packs/organization.js");
require("packs/report.js");
require("packs/email_logs.js");
require("packs/payments.js")
require("packs/verify_modal.js")
require("packs/marketplace.js")
require("jquery")
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

global.$ = jQuery;
