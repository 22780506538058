$(document).ready(function () {
    if ($('.contacts-links-wrapper').children().length > 0) {
        if (window.location.href.includes("contacts_dashboard")) {
            $('.contacts-links-wrapper').children()[0].classList += " active-heading-links"
        } else if (window.location.href.includes("contacts/new")) {
            $('.contacts-links-wrapper').children()[1].classList += " active-heading-links"
        } else {
            $('.contacts-links-wrapper').children()[2].classList += " active-heading-links"
        }
    }
    $("#reset-password").validate({
        onkeyup: false,
        rules: {
            "user[email]": {
                required: true,
            },
        },
        messages: {
            "user[email]": {
                required: "Please enter registered email",
            },
        },
    });

    $(".submit-list").on("click", function (e) {
        e.preventDefault();
        var listName = $("#list-name-text").val()
        if (listName === '' || listName === undefined) {
            if ($(".list-error").hasClass('hide'))
                $('.list-error').removeClass('hide')
        } else {
            if (!$(".list-error").hasClass('hide'))
                $('.list-error').addClass('hide')
            params = {
                name: listName,
                list: {
                    country: $(".contact-filter-form .country-field")[0].value,
                    state: $(".contact-filter-form .state-field")[0].value,
                    tags: [$(".contact-filter-form .tag-field")[0].value],
                    subscribed: $(".contact-filter-form .subscribed-field")[0].value,
                    cleaned: $(".contact-filter-form .cleaned-field")[0].value,
                    emails: $(".contact-filter-form .email-field")[0].value,
                    last_email_sent_at: $(
                        ".contact-filter-form .last-email-sent-at-field"
                    )[0].value,
                    last_email_opened_at: $(".contact-filter-form .last-email-opened-at")[0]
                        .value,
                    last_email_clicked_at: $(
                        ".contact-filter-form .last-email-clicked-at"
                    )[0].value,
                },
            };

            $.ajax({
                type: "POST",
                dataType: "script",
                url: "/organizations/" + this.dataset.slug + "/lists",
                data: params,
            });
        }
    });

    $(".create-list").click(function (e) {
        e.preventDefault();
        M.Modal.init($("#list-name-modal")[0]).open();
    });

});