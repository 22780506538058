$(document).ready(function () {
    $("#change-password").validate({
        onkeyup: false,
        rules: {
            "user[password]": {
                required: true,
                minlength: 8,
            },
            "user[password_confirmation]": {
                required: true,
                equalTo: "#new-pass",
            },
        },
        messages: {
            "user[password]": {
                required: "Please enter a password",
                minlength: "Password must be eight or more character long",
            },
            "user[password_confirmation]": {
                required: "Please re-enter your password",
                equalTo: "Password did not match",
            },
        },
    });
});
