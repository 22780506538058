window.data = {
    index: 1,
    country: 'India',
    end2setup: false,
    ip_amount: 0,
    validations_count: 0
}

document.addEventListener('DOMContentLoaded', function () {
    var modalElements = document.querySelectorAll('#confirm-payment-modal')
    var modalInstances = M.Modal.init(modalElements, {dismissible: false})
    let tooltipElements = document.querySelectorAll('.tooltipped')
    let tooltipInstances = M.Tooltip.init(tooltipElements, {})
    $.get(`${window.location.origin}/states`, {country: 'India'}, (data, status) => {
        let stateSelect = document.getElementById('state-select')
        if (!stateSelect) return;
        for (let i = stateSelect.options.length; i >= 0; i--) {
            stateSelect.remove(i)
        }
        data.data.forEach(state => {
            let opt = document.createElement('option')
            opt.appendChild(document.createTextNode(state[0]))
            opt.value = state[0]
            stateSelect.appendChild(opt)
        })
    })
})

// Main plan details //

const subscriptionPlansContainer = document.getElementById('plan-edit-1')

const planDetailsContainer = document.getElementById('plan-edit-2')

const reviewOrderContainer = document.getElementById('plan-edit-3')

const billingOrderContainer = document.getElementById('plan-edit-4')

if (subscriptionPlansContainer) {
    subscriptionPlansContainer.getElementsByClassName("subscription-content").forEach(item => {
        item.addEventListener("click", event => {
            handleSubscriptionPlanClick(event, item)
        })
    })
}

handleSubscriptionPlanClick = (e, item) => {
    const subscriptionPlansContainer = document.getElementById('plan-edit-1')
    const planDetailsContainer = document.getElementById('plan-edit-2')
    subscriptionPlansContainer.getElementsByClassName("subscription-content").forEach(item => {
            item.classList.remove('selected_plan')
    })
    item.classList.add('selected_plan')

    subscriptionPlansContainer.classList.remove('content-shown')
    subscriptionPlansContainer.classList.add('content-hidden')
    planDetailsContainer.classList.remove('content-hidden')
    planDetailsContainer.classList.add('content-shown')

    data.plan_id = item.getAttribute('planid')
    data.index = 2
    data.plan_price = Number(item.getAttribute('planprice'))
    if (window.discountActive === 'true') {
        data.plan_price = data.plan_price - (5 / 100 * data.plan_price)
    }
    data.email_sending_limit = Number(item.getAttribute('emailcount'))
    document.getElementById('plan-name').innerText = item.children[1].innerText
    document.getElementById('plan-rate').innerText = `${item.children[3].innerText.split('/')[0]}/month`
    document.getElementById('plan-price').innerText = item.children[3].innerText.split('/')[0]

    if (window.hundredKPrice) {
        const planContainer = document.getElementById('selected-plan-details-container')
        const planContainerParent = planContainer.parentElement
        window.emailsPrice = Number(window.hundredKPrice) * (Number(item.getAttribute('emailcount')) / 100000)

        const tempElem = planContainerParent.getElementsByClassName('temp-wrapper')

        while (tempElem[0]) {
            tempElem[0].parentNode.removeChild(tempElem[0]);
        }

        const newELement = document.createElement('div')
        newELement.classList.add('temp-wrapper')

        let childElem = document.createElement('p')
        childElem.innerText = 'Email Price'

        newELement.appendChild(childElem)

        childElem = document.createElement('p')
        childElem.innerText = '100k emails'
        newELement.appendChild(childElem)

        childElem = document.createElement('p')
        childElem.innerText = `${window.moneySymbol} ${window.hundredKPrice}`
        newELement.appendChild(childElem)

        childElem = document.createElement('p')
        childElem.innerText = `${window.moneySymbol} ${window.emailsPrice}`
        newELement.appendChild(childElem)


        planContainerParent.insertBefore(newELement, planContainer.nextSibling)
    }
    document.getElementById('total-plan-price').innerText = `${window.moneySymbol}${(data.plan_price + Number(window.emailsPrice || 0)).toLocaleString()}`
}


handleInputValueChange = (event) => {
    if (Number(event.target.value) < 0) {
        event.target.value = 0
        return
    }
    let ipPrice = document.getElementById('total-ip-price')
    let end2setup = document.getElementById('end2setup')
    const end2price = end2setup.checked ? window.endToEndPrice : 0
    ipPrice.innerText = `${window.moneySymbol}${(Number(window.privateIpPrice) * Number(event.target.value)).toLocaleString()}`
    data.ip_amount = Number(event.target.value)

    document.getElementById('total-plan-price').innerText = `${window.moneySymbol}${(data.plan_price + (Number(privateIpPrice || 0) * data.ip_amount) + Number(end2price) + Number(window.emailsPrice || 0) + (Number(window.validationsPrice || 0) * Number(data.validations_count || 0))).toLocaleString()}`
}

handleOrderConfirm = (event) => {
    const reviewOrderContainer = document.getElementById('plan-edit-3')
    const billingOrderContainer = document.getElementById('plan-edit-4')
    const finalPayment = document.getElementById('final-confirm-payment-id')

    reviewOrderContainer.classList.remove('content-shown')
    reviewOrderContainer.classList.add('content-hidden')
    billingOrderContainer.classList.remove('content-hidden')
    billingOrderContainer.classList.add('content-shown')
    data.totalAmount = document.getElementById('total-plan-price').innerText

    finalPayment.innerHTML = `After confirming your order, you will receive an email with an invoice of ${data.totalAmount}`

    data.index = 4
}

handleEmailValidationsInputChange = (event) => {
    if (Number(event.target.value) < 0) {
        event.target.value = 0
        return
    }
    let validPrice = document.getElementById('total-validation-email-price')
    let end2setup = document.getElementById('end2setup')
    const end2price = end2setup.checked ? window.endToEndPrice : 0
    validPrice.innerText = `${window.moneySymbol}${(Number(window.validationsPrice) * Number(event.target.value)).toLocaleString()}`
    data.validations_count = Number(event.target.value)
    document.getElementById('total-plan-price').innerText = `${window.moneySymbol}${(data.plan_price + (Number(privateIpPrice) * data.ip_amount) + Number(end2price) + Number(window.emailsPrice || 0) + (Number(window.validationsPrice) * Number(data.validations_count))).toLocaleString()}`
}


handleCheckBoxClick = (event) => {
    data.end2setup = event.target.checked
    let ipPrice = document.getElementById('total-ip-price')
    let planPrice = document.getElementById('plan-price')

    if (event.target.checked) {
        document.getElementById('end-2-p-fianl-rate').innerHTML = `${window.moneySymbol}${Number(window.endToEndPrice).toLocaleString()}`
        document.getElementById('total-plan-price').innerHTML =
            `${window.moneySymbol}${(data.plan_price + Number(window.emailsPrice || 0) + (Number(window.validationsPrice) * Number(data.validations_count))
                + Number(ipPrice.innerText.split(window.moneySymbol)[1].split(',').join('') || 0)
                + Number(event.target.value)).toLocaleString()}`
    } else {
        document.getElementById('end-2-p-fianl-rate').innerHTML = `${window.moneySymbol}0`
        document.getElementById('total-plan-price').innerHTML = `
      ${window.moneySymbol}${(data.plan_price + Number(window.emailsPrice || 0) + (Number(window.validationsPrice) * Number(data.validations_count))
            + Number(ipPrice.innerText.split(window.moneySymbol)[1].split(',').join('') || 0)).toLocaleString()}`
    }
}

gotoReviewOrder = (event) => {
    const planDetailsContainer = document.getElementById('plan-edit-2')
    const reviewOrderContainer = document.getElementById('plan-edit-3')
    const reviewOrderWrapper = reviewOrderContainer.getElementsByClassName('review-order-content-wrapper')[0]

    const elements = reviewOrderWrapper.getElementsByClassName('generated-order')

    while (elements[0]) {
        elements[0].parentNode.removeChild(elements[0]);
    }

    planDetailsContainer.classList.remove('content-shown')
    planDetailsContainer.classList.add('content-hidden')
    reviewOrderContainer.classList.remove('content-hidden')
    reviewOrderContainer.classList.add('content-shown')

    let subcriptionPlanElement = document.createElement('div')
    subcriptionPlanElement.classList.add('generated-order')

    let customelement = document.createElement('p')
    customelement.innerText = document.getElementById('plan-name').innerText
    subcriptionPlanElement.appendChild(customelement)

    let customelement1 = document.createElement('p')
    customelement1.innerText = document.getElementById('plan-price').innerText
    subcriptionPlanElement.appendChild(customelement1)

    let customelement2 = document.createElement('p')
    customelement2.innerText = '-'
    subcriptionPlanElement.appendChild(customelement2)

    let customelement3 = document.createElement('p')
    customelement3.innerText = `${document.getElementById('plan-price').innerText}/ month`
    subcriptionPlanElement.appendChild(customelement3)

    if (reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0]) {
        reviewOrderWrapper.insertBefore(subcriptionPlanElement, reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0])
    } else {
        reviewOrderWrapper.insertBefore(subcriptionPlanElement, reviewOrderWrapper.getElementsByClassName('total-amount-wrapper')[0])
    }

    if (data.ip_amount) {
        let pricateIpElement = document.createElement('div')
        pricateIpElement.classList.add('generated-order')

        let ce = document.createElement('p')
        ce.innerText = 'Private Ip'
        pricateIpElement.appendChild(ce)

        let ce1 = document.createElement('p')
        ce1.innerText = `${window.moneySymbol}${Number(window.privateIpPrice).toLocaleString()}`
        pricateIpElement.appendChild(ce1)

        let ce3 = document.createElement('p')
        ce3.innerText = data.ip_amount
        pricateIpElement.appendChild(ce3)

        let ce4 = document.createElement('p')
        ce4.innerText = `${window.moneySymbol}${(Number(data.ip_amount) * Number(window.privateIpPrice)).toLocaleString()}/ month`
        pricateIpElement.appendChild(ce4)

        if (reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0]) {
            reviewOrderWrapper.insertBefore(pricateIpElement, reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0])
        } else {
            reviewOrderWrapper.insertBefore(pricateIpElement, reviewOrderWrapper.getElementsByClassName('total-amount-wrapper')[0])
        }

        data.privateIpId = document.getElementById('pvt-ip-id').getAttribute('data')
    }

    if (data.end2setup) {
        let end2pelement = document.createElement('div')
        end2pelement.classList.add('generated-order')

        let es = document.createElement('p')
        es.innerText = 'End 2 end setup'
        end2pelement.appendChild(es)

        let es1 = document.createElement('p')
        es1.innerText = `${window.moneySymbol}${Number(window.endToEndPrice).toLocaleString()}`
        end2pelement.appendChild(es1)

        let es3 = document.createElement('p')
        es3.innerText = ''
        end2pelement.appendChild(es3)

        let es4 = document.createElement('p')
        es4.innerText = `${window.moneySymbol}${Number(window.endToEndPrice).toLocaleString()}`
        end2pelement.appendChild(es4)

        if (reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0]) {
            reviewOrderWrapper.insertBefore(end2pelement, reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0])
        } else {
            reviewOrderWrapper.insertBefore(end2pelement, reviewOrderWrapper.getElementsByClassName('total-amount-wrapper')[0])
        }
        data.endToEndID = document.getElementById('end2setup').getAttribute('data')
    }

    if (Number(window.hundredKPrice)) {
        let emailSendingElement = document.createElement('div')
        emailSendingElement.classList.add('generated-order')

        let es = document.createElement('p')
        es.innerText = '100K Emails'
        emailSendingElement.appendChild(es)

        let es1 = document.createElement('p')
        es1.innerText = `${window.moneySymbol}${Number(window.hundredKPrice).toLocaleString()}`
        emailSendingElement.appendChild(es1)

        let es3 = document.createElement('p')
        es3.innerText = data.email_sending_limit / 100000
        emailSendingElement.appendChild(es3)

        let es4 = document.createElement('p')
        es4.innerText = `${window.moneySymbol}${(data.email_sending_limit / 100000 * Number(window.hundredKPrice)).toLocaleString()}`
        emailSendingElement.appendChild(es4)


        if (reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0]) {
            reviewOrderWrapper.insertBefore(emailSendingElement, reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0])
        } else {
            reviewOrderWrapper.insertBefore(emailSendingElement, reviewOrderWrapper.getElementsByClassName('total-amount-wrapper')[0])
        }
        data.endToEndID = document.getElementById('end2setup').getAttribute('data')
    }

    if (data.validations_count) {
        let pricateIpElement = document.createElement('div')
        pricateIpElement.classList.add('generated-order')

        let ce = document.createElement('p')
        ce.innerText = 'Validations'
        pricateIpElement.appendChild(ce)

        let ce1 = document.createElement('p')
        ce1.innerText = `${window.moneySymbol}${Number(window.validationsPrice).toLocaleString()}`
        pricateIpElement.appendChild(ce1)

        let ce3 = document.createElement('p')
        ce3.innerText = data.validations_count
        pricateIpElement.appendChild(ce3)

        let ce4 = document.createElement('p')
        ce4.innerText = `${window.moneySymbol}${(Number(data.validations_count) * Number(window.validationsPrice)).toLocaleString()}/ 20k emails`
        pricateIpElement.appendChild(ce4)

        if (reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0]) {
            reviewOrderWrapper.insertBefore(pricateIpElement, reviewOrderWrapper.getElementsByClassName('discount-amount-wrapper')[0])
        } else {
            reviewOrderWrapper.insertBefore(pricateIpElement, reviewOrderWrapper.getElementsByClassName('total-amount-wrapper')[0])
        }
    }
    document.getElementById('order-total-amount').innerText = document.getElementById('total-plan-price').innerText

    data.index = 3

}
//

// edit button//        
const edit1 = document.getElementById('edit-1')

if (edit1) {
    edit1.addEventListener('click', e => {
        const subscriptionPlansContainer = document.getElementById('plan-edit-1')
        const planDetailsContainer = document.getElementById('plan-edit-2')
        const reviewOrderContainer = document.getElementById('plan-edit-3')
        const billingOrderContainer = document.getElementById('plan-edit-4')

        subscriptionPlansContainer.classList.remove('content-hidden')
        subscriptionPlansContainer.classList.add('content-shown')

        planDetailsContainer.classList.remove('content-shown')
        planDetailsContainer.classList.add('content-hidden')

        reviewOrderContainer.classList.remove('content-shown')
        reviewOrderContainer.classList.add('content-hidden')

        billingOrderContainer.classList.remove('content-shown')
        billingOrderContainer.classList.add('content-hidden')
    })
}
const edit3 = document.getElementById('edit-3')
if (edit3) {
    edit3.addEventListener('click', e => {
        if (data.index <= 2) {
            return
        }
        const subscriptionPlansContainer = document.getElementById('plan-edit-1')
        const planDetailsContainer = document.getElementById('plan-edit-2')
        const reviewOrderContainer = document.getElementById('plan-edit-3')
        const billingOrderContainer = document.getElementById('plan-edit-4')

        reviewOrderContainer.classList.remove('content-hidden')
        reviewOrderContainer.classList.add('content-shown')

        planDetailsContainer.classList.remove('content-shown')
        planDetailsContainer.classList.add('content-hidden')

        subscriptionPlansContainer.classList.remove('content-shown')
        subscriptionPlansContainer.classList.add('content-hidden')

        billingOrderContainer.classList.remove('content-shown')
        billingOrderContainer.classList.add('content-hidden')
    })
}

const edit2 = document.getElementById('edit-2')
if (edit2) {
    edit2.addEventListener('click', e => {
        if (data.index <= 1) {
            return
        }
        data.index = 2
        const subscriptionPlansContainer = document.getElementById('plan-edit-1')
        const planDetailsContainer = document.getElementById('plan-edit-2')
        const reviewOrderContainer = document.getElementById('plan-edit-3')
        const billingOrderContainer = document.getElementById('plan-edit-4')

        planDetailsContainer.classList.remove('content-hidden')
        planDetailsContainer.classList.add('content-shown')

        subscriptionPlansContainer.classList.remove('content-shown')
        subscriptionPlansContainer.classList.add('content-hidden')

        reviewOrderContainer.classList.remove('content-shown')
        reviewOrderContainer.classList.add('content-hidden')

        billingOrderContainer.classList.remove('content-shown')
        billingOrderContainer.classList.add('content-hidden')
    })
}

const edit4 = document.getElementById('edit-4')
if (edit4) {
    edit4.addEventListener('click', e => {
        if (data.index <= 3) {
            return
        }
        const subscriptionPlansContainer = document.getElementById('plan-edit-1')
        const planDetailsContainer = document.getElementById('plan-edit-2')
        const reviewOrderContainer = document.getElementById('plan-edit-3')
        const billingOrderContainer = document.getElementById('plan-edit-4')

        billingOrderContainer.classList.remove('content-hidden')
        billingOrderContainer.classList.add('content-shown')

        planDetailsContainer.classList.remove('content-shown')
        planDetailsContainer.classList.add('content-hidden')

        reviewOrderContainer.classList.remove('content-shown')
        reviewOrderContainer.classList.add('content-hidden')

        subscriptionPlansContainer.classList.remove('content-shown')
        subscriptionPlansContainer.classList.add('content-hidden')
    })
}

//

confirmOrder = () => {
    const data = window.data
    let loader = document.getElementById('modal-pre-loader')
    let btn1 = document.getElementById('modal-footer-1')
    let btn2 = document.getElementById('modal-footer-2')
    loader.classList.add('active')
    btn1.classList.add('disabled')
    btn2.classList.add('disabled')
    let modal = document.getElementById('confirm-payment-modal')

    modal.classList.add('modal-loading')

    $.post(window.location.href, data).done((resp, status) => {
        if (status === 'success') {
            let currentUrl = window.location.href.split('/')
            currentUrl.pop()
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: resp['invoice_url'],
            }).click();
            window.location.href = currentUrl.join('/')
        }
    })
}

handlePaymentInputChange = (type, event) => {
    event.target.nextElementSibling.classList.add('hidden')
    event.target.classList.remove('error')
    window.data = {...window.data, [type]: event.target.value}
}

handleCountryChange = event => {
    window.data = {...window.data, country: event.target.value}
    $.get(`${window.location.origin}/states`, {country: event.target.value}, (data, status) => {
        let stateSelect = document.getElementById('state-select')
        for (let i = stateSelect.options.length; i >= 0; i--) {
            stateSelect.remove(i)
        }
        data.data.forEach(state => {
            let opt = document.createElement('option')
            opt.appendChild(document.createTextNode(state[0]))
            opt.value = state[0]
            stateSelect.appendChild(opt)
        })
    })
}

handleProceedToPay = () => {
    let modalInstance = M.Modal.getInstance(document.getElementById('confirm-payment-modal'))
    let billingInputs = document.getElementsByClassName('billing-details')
    let errorLabels = document.getElementsByClassName('error-label')
    let valid = true
    for (let i = 0; i < billingInputs.length; i++) {
        if (!billingInputs[i].value) {
            billingInputs[i].classList.add('error')
            errorLabels[i].classList.remove('hidden')
            valid = false
        }
    }
    if (valid) modalInstance.open()
}
