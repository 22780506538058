window.addEventListener('DOMContentLoaded', event => {
    let datepickers = document.querySelectorAll('.datepicker');
    let datePickerinstances = M.Datepicker.init(datepickers, {})
    let dropdowns = document.querySelectorAll('.dropdown-trigger')
    let dropdownInstances = M.Dropdown.init(dropdowns, {})
    const parsedUrl = new URL(window.location)
    if (parsedUrl.searchParams.get('date_range')) {
        const dateRangeSelect = document.getElementById('date-range-filter')
        for (let i = 0; i < dateRangeSelect.options.length; i++) {
            if (dateRangeSelect.options[i].value === parsedUrl.searchParams.get('date_range')) {
                dateRangeSelect.selectedIndex = i
                const dateRangeInputsWrapper = document.getElementById('date-range-input-wrapper')
                if (dateRangeSelect.options[i].value === '1' && dateRangeInputsWrapper.classList.contains('hidden')) {
                    dateRangeInputsWrapper.classList.remove('hidden')
                    const startDateInput = document.getElementById('start-date')
                    const endDateInput = document.getElementById('end-date')
                    startDateInput.value = parsedUrl.searchParams.get('start_date')
                    endDateInput.value = parsedUrl.searchParams.get('end_date')
                }
                document.getElementsByClassName('select-selected')[0].innerHTML = dateRangeSelect.options[i].innerHTML
                document.getElementsByClassName('select-selected')[0].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[0].children[0].innerHTML = `- ${dateRangeSelect.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[0].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[0].children[0].addEventListener('click', e => {
                    let url = removeExistingUrlParameters(['date_range'])
                    url.includes('?') ? window.location.replace(`${url}&date_range=2`) : window.location.replace(`${url}?date_range=2`)
                })
            }
        }
    }
    if (parsedUrl.searchParams.get('campaign_id')) {
        const campaignSelect = document.getElementById('campaign-filter')
        for (let i = 0; i < campaignSelect.options.length; i++) {
            if (campaignSelect.options[i].value === parsedUrl.searchParams.get('campaign_id')) {
                campaignSelect.selectedIndex = i
                document.getElementsByClassName('select-selected')[1].innerHTML = campaignSelect.options[i].innerHTML
                document.getElementsByClassName('select-selected')[1].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[1].children[0].innerHTML = `- ${campaignSelect.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[1].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[1].children[0].addEventListener('click', e => clearFilter('campaign_id'))
            }
        }
    }
    if (parsedUrl.searchParams.get('list_filter')) {
        const queryFilter = document.getElementById('email-filter')
        for (let i = 0; i < queryFilter.options.length; i++) {
            if (queryFilter.options[i].value === parsedUrl.searchParams.get('list_filter')) {
                queryFilter.selectedIndex = i
                document.getElementsByClassName('select-selected')[3].innerHTML = queryFilter.options[i].innerHTML
                document.getElementsByClassName('select-selected')[3].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[3].children[0].innerHTML = `- ${queryFilter.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[3].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[3].children[0].addEventListener('click', e => clearFilter('list_filter'))
            }
        }
    }
    if (parsedUrl.searchParams.get('sending_address')) {
        const queryFilter = document.getElementById('email-filter')
        for (let i = 0; i < queryFilter.options.length; i++) {
            if (queryFilter.options[i].value === parsedUrl.searchParams.get('sending_address')) {
                queryFilter.selectedIndex = i
                document.getElementsByClassName('select-selected')[2].innerHTML = queryFilter.options[i].innerHTML
                document.getElementsByClassName('select-selected')[2].classList.add('value-selected')
                const icon = document.createElement('i')
                icon.classList.add("tiny")
                icon.classList.add("material-icons")
                icon.innerHTML = 'clear'
                document.getElementsByClassName('select-items')[2].children[0].innerHTML = `- ${queryFilter.options[i].innerHTML} -`
                document.getElementsByClassName('select-items')[2].children[0].appendChild(icon)
                document.getElementsByClassName('select-items')[2].children[0].addEventListener('click', e => clearFilter('sending_address'))
            }
        }
    }

    const selectedSelects = document.getElementsByClassName('select-selected')

    if (selectedSelects) {
        selectedSelects.forEach((item, i) => {
            item.innerHTML = item.innerHTML.length >= 14 ? item.innerHTML.split('').slice(0, 14).join('') + '..' : item.innerHTML
        });
    }
})

const clearFilter = (filterName) => {
    const newURL = new URL(window.location)
    let urlGenerator = Array.from(newURL.searchParams.keys()).filter(e => e !== filterName)
    let toGoUrl = window.location.pathname
    urlGenerator.forEach((item, index) => {
        if (index === 0) {
            toGoUrl = toGoUrl + `?${item}=${newURL.searchParams.get(item)}`
            return
        }
        toGoUrl = toGoUrl + `&${item}=${newURL.searchParams.get(item)}`
    })
    window.location.replace(toGoUrl)
}

const dateRangeSelect = document.getElementById('date-range-filter')

if (dateRangeSelect)
    dateRangeSelect.addEventListener('change', e => {
        const dateRangeInputsWrapper = document.getElementById('date-range-input-wrapper')
        if (e.target.value && e.target.value === 'clear') {
            return
        }
        if (e.target.value && e.target.value === '1' && dateRangeInputsWrapper.classList.contains('hidden')) {
            dateRangeInputsWrapper.classList.remove('hidden')
            return
        } else dateRangeInputsWrapper.classList.add('hidden')
        const url = removeExistingUrlParameters(['date_range', 'start_date', 'end_date'])
        if (url.includes('?')) {
            window.location.replace(`${url}&date_range=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?date_range=${e.target.value}`)
    })

const startDateInput = document.getElementById('start-date')

if (startDateInput) {
    startDateInput.addEventListener('change', e => {
        if (!endDateInput.value || !e.target.value) return

        const url = removeExistingUrlParameters(['start_date', 'end_date', 'date_range'])
        const startDate = new Date(e.target.value).toDateString()
        const endDate = new Date(endDateInput.value).toDateString()
        if (url.includes('?')) {
            window.location.replace(`${url}&start_date=${startDate}&end_date=${endDate}&date_range=1`)
            return
        }
        window.location.replace(`${url}?start_date=${startDate}&end_date=${endDate}&date_range=1`)
    })
}

const endDateInput = document.getElementById('end-date')

if (endDateInput) {
    endDateInput.addEventListener('change', e => {
        if (!startDateInput.value || !e.target.value) return

        const url = removeExistingUrlParameters(['start_date', 'end_date', 'date_range'])
        const startDate = new Date(startDateInput.value).toDateString()
        const endDate = new Date(e.target.value).toDateString()
        if (url.includes('?')) {
            window.location.replace(`${url}&start_date=${startDate}&end_date=${endDate}&date_range=1`)
            return
        }
        window.location.replace(`${url}?start_date=${startDate}&end_date=${endDate}&date_range=1`)
    })
}

const campaignSelect = document.getElementById('campaign-filter')

if (campaignSelect)
    campaignSelect.addEventListener('change', e => {
        if (e.target.value && e.target.value === 'clear') {
            return
        }

        const url = removeExistingUrlParameters(['campaign_id'])
        if (url.includes('?')) {
            window.location.replace(`${url}&campaign_id=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?campaign_id=${e.target.value}`)
    })

const filtersSelect = document.getElementById('query-filter')

if (filtersSelect)
    filtersSelect.addEventListener('change', e => {
        if (e.target.value && e.target.value === 'clear') {
            return
        }

        const url = removeExistingUrlParameters(['list_filter'])
        if (url.includes('?')) {
            window.location.replace(`${url}&list_filter=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?list_filter=${e.target.value}`)
    })

const emailSelect = document.getElementById('email-filter')

if (emailSelect)
    emailSelect.addEventListener('change', e => {
        if (e.target.value && e.target.value === 'clear')
            return

        const url = removeExistingUrlParameters(['sending_address'])
        if (url.includes('?')) {
            window.location.replace(`${url}&sending_address=${e.target.value}`)
            return
        }
        window.location.replace(`${url}?sending_address=${e.target.value}`)
    })

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
const removeExistingUrlParameters = names => {
    const url = new URL(window.location.href)
    names.forEach(name => url.searchParams.delete(name))
    return url.href
}

const clearAll = document.getElementById('clear-all')

if (clearAll) {
    clearAll.addEventListener('click', e => {
        const url = removeExistingUrlParameters(['date_range', 'start_date', 'end_date', 'sending_address', 'campaign_id', 'list_filter', 'status'])
        window.location.replace(`${url}?date_range=2`)
    })
}
