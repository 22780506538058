$(document).ready(function () {
  const DEFAULT_SENDING_DOMAIN = "campaign.SendBoost.com";
  const DEFAULT_SENDING_LIMIT = 50;

  $("#campaign-create-form").on("submit", function () {
    if ($(".tab .active")[0].text === "Regular")
      $("#campaign_mail_type").val("regular");
    else if ($(".tab .active")[0].text === "Automated")
      $("#campaign_mail_type").val("automated");
    else $("#campaign_mail_type").val("plain_text");
  });
  
  var timer = $(".campaign-timepicker").datetimepicker({
    format: "d/m/Y H:i",
    formatTime: "H:i",
    formatDate: "d/m/Y",
    step: 15 // Adding 30-minute interval
});

var rescheduleTimer = $(".campaign-rescheduled-timepicker").datetimepicker({
    format: "d/m/Y H:i",
    formatTime: "H:i",
    formatDate: "d/m/Y",
    step: 15 // Adding 30-minute interval
});


  $("#schedule").prop("disabled", true);

  $(timer).on("change", function (e) {
    $("#scheduled_date").val(this.value);
    if ($("#scheduled_date")[0].value == "") {
      if (!$(".schedule-label").hasClass("hide")) {
        $(".schedule-label").addClass("hide");
      }
      $(".submit-button").val("Send");
    } else {
      if ($(".schedule-label").hasClass("hide")) {
        $(".schedule-label").removeClass("hide");
      }
      $(".schedule-time").html($("#scheduled_date")[0].value);
      $("#schedule").val("Schedule Now!");
      if ($('#schedule').hasClass("disabled")){
        $('#schedule').removeClass("disabled")
      }
      $("#schedule").prop("disabled", false);
    }
  });

  $("#schedule").on("click", function (e) {
    // $("#scheduled_date").val(this.value);
    if ($("#scheduled_date")[0].value == "") {
      if (!$(".schedule-label").hasClass("hide")) {
        $(".schedule-label").addClass("hide");
      }
      $(".submit-button").val("Send");
    } else {
      if ($(".schedule-label").hasClass("hide")) {
        $(".schedule-label").removeClass("hide");
      }
      $(".schedule-time").html($("#scheduled_date")[0].value);
      $("#schedule").prop("disabled", false);
    }
  });

  let send = document.getElementById("send");
  if (send) {
    send.addEventListener("click", function (event) {
      event.preventDefault();
      $("#scheduled_date").val("");
      $("#params_form").submit();
    });
  }

  $("#reschedulebtn").prop("disabled", true);
  $(rescheduleTimer).on("change", function (e) {
    $("#scheduled_date").val(this.value);
    $(".rescheduled-time").html(
      "Schedule for " + $("#scheduled_date")[0].value
    );
    $("#cancel-campaign").val("false");
    $("#reschedulebtn").prop("disabled", false);
    $(".submit-button").val("Schedule");
  });

  $("#cancelbtn").on("click", function (e) {
    e.preventDefault();
    $("#scheduled_date").val("");
    $("#cancel-campaign").val("true");
    if ($("#scheduled_date")[0].value == "") {
      $("#cancel_form").submit();
    }
  });

  // $("#reschedulebtn").on("click", function (e) {
  //   e.preventDefault();

  //   if ($("#scheduled_date")[0].value == "") {
  //     $("#cancel_form").submit();
  //   }
  // });

  $("#smart-schedule").on("submit", function () {
    if (
      confirm(
        "After this you won't be able to edit the campaign, are you sure ?"
      )
    ) {
      return true;
    } else {
      return false;
    }
  });

  if ($("#campaign_mail_from_name").val() == '' || $("#campaign_subject").val() == ''){
    $(".option-list").addClass("disabled-link");
      $("#sample__email").addClass("disabled-link");
  }

  $("#campaign-form :input").on("change", function () {
    if (!$(".option-list").hasClass("disabled-link")) {
      $(".option-list").addClass("disabled-link");
      $("#sample__email").addClass("disabled-link");
    }
    if ($(".save-error").hasClass("hide")) $(".save-error").removeClass("hide");
  });

  $.validator.addMethod(
    "checkContactsLimit",
    function (value, element) {
      if (
        $("#campaign_mail_from").text() &&
        $("#campaign_mail_from").text().split("@")[1] === DEFAULT_SENDING_DOMAIN
      ) {
        if (
          Number(
            $("#campaign_list_id :selected")
              .text()
              .split(" contacts)")[0]
              .split("(")[1]
          ) > DEFAULT_SENDING_LIMIT
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    "Only 50 contacts are allowed for default sending address"
  );

  $("#campaign-form").validate({
    ignore: [],
    onkeyup: false,
    errorPlacement: function (error, element) {
      if (element[0].name === "campaign[content]")
        error.insertAfter($("#tinymce_for_content"));
      else error.insertAfter(element);
    },
    rules: {
      "campaign[mail_from_name]": {
        required: true,
      },
      "campaign[mail_from]": {
        required: true,
      },
      "campaign[subject]": {
        required: true,
      },
      "campaign[list_id]": {
        required: true,
        checkContactsLimit: true,
      },
      "campaign[content]": {
        required: true,
      },
    },
    messages: {
      "campaign[mail_from_name]": {
        required: "Please enter the name",
      },
      "campaign[mail_from]": {
        required:
          "Please add and verify your sending address to send this campaign",
      },
      "campaign[subject]": {
        required: "Please enter the subject of the email",
      },
      "campaign[list_id]": {
        required: "Please select a list",
      },
      "campaign[content]": {
        required: "Please enter the content",
      },
    },
  });
  let campaignCreateInput = document.getElementById("create-input");
  if (campaignCreateInput) {
    campaignCreateInput.addEventListener("keyup", function () {
      let campaignMailType = document.getElementById("campaign-mail-type");
      if ($(".tab .active")[0].text === "campaignRegular") {
        campaignMailType.value = "regular";
        console.log("regular");
      } else if ($(".tab .active")[0].text === "Automated") {
        campaignMailType.value = "automated";
        console.log("automated");
      } else if ($(".tab .active")[0].text === "edit_notePlain-text") {
        campaignMailType.value = "plain_text";
        console.log("regular");
      } else if ($(".tab .active")[0].text === "apiTransactional") {
        campaignMailType.value = "transactional";
      }
    });
  }
});
