$(document).ready(function () {
    $("#registration-form").validate({
        onkeyup: false,
        rules: {
            "user[name]": {
                required: true,
                maxlength: 100,
            },
            "user[email]": {
                required: true,
                validEmail: true,
                maxlength: 100,
            },
            "user[password]": {
                required: true,
                minlength: 8,
            },
            "user[password_confirmation]": {
                required: true,
                equalTo: "#user-password",
            },
        },
        messages: {
            "user[name]": {
                required: "Please enter your name",
                maxlength: 100,
            },
            "user[email]": {
                required: "Please enter your email",
                validEmail: "Please enter a valid email address",
                maxlength: 100,
            },
            "user[password]": {
                required: "Please enter a password",
                minlength: "Password must be eight or more character long",
            },
            "user[password_confirmation]": {
                required: "Please re-enter your password",
                equalTo: "Password did not match",
            },
        },
    });
});
