$(document).ready(function () {
    $("#reset-password").validate({
        onkeyup: false,
        rules: {
            "user[email]": {
                required: true,
            }
        },
        messages: {
            "user[email]": {
                required: "Please enter registered email",
            }
        },
    });
});
