$(document).ready(function () {
    $("#login-form").validate({
        onkeyup: false,
        rules: {
            "user[email]": {
                required: true,
            },
            "user[password]": {
                required: true,
            },
        },
        messages: {
            "user[email]": {
                required: "Please enter registered email",
            },
            "user[password]": {
                required: "Please enter your password",
            },
        },
    });
});
