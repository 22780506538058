$(document).ready(function () {
    $("#organization-form").validate({
        onkeyup: false,
        errorPlacement: function (error, element) {
            if (element[0].name !== "generic[phone]"){
                error.insertAfter(element);
            }
        },
        rules: {
            "organization[name]": {
                required: true,
            },
            "organization[website_url]": {
                required: true,
                // url: true
            },
            "organization[country]": {
                required: true,
            },
            "organization[phone]": {
                required: true,
            },
            "generic[phone]": {
                required: true,
            },
            "organization[timezone]": {
                required: true,
            }
        },
        messages: {
            "organization[name]": {
                required: "Please enter organization name",
            },
            "organization[website_url]": {
                required: "Please enter website URL",
            },
            "organization[country]": {
                required: "Please select country",
            },
            "organization[phone]": {
                required: "Please add phone number with country code",
            },
            "organization[timezone]": {
                required: "Please select your timezone",
            },
            "organization[sector]": {
                required: "Please select your sector",
            },
        },
    });
});
